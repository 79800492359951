import { AppInstance } from '@/api/maintain/app-instance'
import { showModal } from '@/libs/modal-helper'
import modal from './app-instance-opt-modal.vue'

export default function showAppInstanceOptModal (instanceItem: AppInstance): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    showModal<number>(modal, {
      props: {
        instanceItem: instanceItem
      }
    }, true, (id: number) => {
      resolve(id)
      return new Promise<void>((resolve, reject) => {
        resolve()
      })
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
