
import { AppInstance } from '@/api/maintain/app-instance'
import { showModal } from '@/libs/modal-helper'
import modal from './nginx-mount-instance-modal.vue'

export default function showNginxMountInstanceModal (nginxInstance: AppInstance): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {
      props: {
        nginxInstance: nginxInstance
      }
    }, true, () => {
      return new Promise<void>((resolve, reject) => {
        resolve()
      })
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
