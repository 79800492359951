
import { Component, Vue } from 'vue-property-decorator'
import ProjectSelector from '@/components/common-select/project-selector.vue'
import ApplicationSelector from '@/components/common-select/application-selector.vue'
import ApplicationApi, { Application } from '@/api/maintain/application'
import { AppInstance } from '@/api/maintain/app-instance'
import ApplicationTypeSelector from '@/components/common-select/application-type-selector.vue'
import ElevatorRabbitmq from '@/mixins/elevator-rabbitmq'
import PcServerSelectorInApp from './components/selector/pc-server-selector-in-app.vue'
import { ApplicationWebSocket } from '@/libs/application-websocket'
import { getterUserInfo } from '@/store'
import ApplicationContainer from './components/application-container.vue'
import {
  appInstanceStatus,
  AppIntaceAtPc,
  MonitingAppIntance,
  MonitingPcCollection
} from './moniting-appintace-helper'
import showAddApplicationModal from './components/modal/show-add-application-modal'
import appInstanceManger from '@/api/maintain/app-instance-manger'
import EmptyPage from '@/components/empty-page.vue'
import PcServerApi, { PcServer } from '@/api/maintain/pc-server'
import moment from 'moment'
import showExportCommandsModal from './modal/export-commands-modal'

export interface App extends Application {
  instanceList: AppInstance[];
}

@Component({
  name: 'ShowPage',
  components: {
    ProjectSelector,
    ApplicationSelector,
    ApplicationTypeSelector,
    PcServerSelectorInApp,
    EmptyPage,
    ApplicationContainer
  },
  mixins: [ElevatorRabbitmq]
})
export default class ShowPage extends Vue {
  /** 应用查询条件 */
  queryApplicationData = {
    page: 1,
    pageSize: 6,
    sorts: ['-modifyTime'],
    name: null,
    appType: null,
    pcServerId: undefined as undefined|number,
    tags: undefined,
    hideEmptyApp: false
  };

  /// 当前页号
  curPage = 1;
  /// 当前页大小
  pageSize = 6;
  /// 总数据行数
  total = 0;

  /// 在监控的数据
  monitingPcs: MonitingPcCollection = {};
  /// 连接列表
  wssScoketList: Record<string, ApplicationWebSocket> = {};
  /** 全部实例监控数据列表 */
  allMonitingAppIntance: MonitingAppIntance[]=[]
  /** 服务器列表 */
  pcServers: PcServer[]=[]

  appList: Array<Application> = []; // 应用集合

  /** 当前选择服务器ip */
  serverIp = '';

  firstPcServerId=0

  /** 当前选中服务器id */
  pcServerId: number|undefined=-1

  /**
   * 当前编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateApplication
  }

  get canOperatePcServer () {
    const user = getterUserInfo()
    return user.canOperatePcServer
  }

  /**
   * 是否开通企业用户
   */
  get opening () {
    const user = getterUserInfo()
    return user.operaExpirationTime && moment(user.operaExpirationTime).isAfter(moment.now())
  }

  /** 所有监控实例列表 */
  setAllMonitingAppIntance () {
    let allInstace: MonitingAppIntance[] = []
    for (const key in this.monitingPcs) {
      allInstace = allInstace.concat(this.monitingPcs[key])
    }
    this.allMonitingAppIntance = allInstace
  }

  activated () {
    PcServerApi.getFirstPcServerId().then((res) => {
      this.firstPcServerId = res.data || 0
    })
    PcServerApi.getAllPcServers().then((res) => {
      this.pcServers = res.data || []
    })
    this.doPullDataList()
  }

  /** 选中服务器变更 */
  pcServerChange (val: any) {
    this.serverIp = val.ip
  }

  /** 设置选中服务器ip */
  setServerIp (val?: number) {
    this.pcServerId = val
  }

  /**
   * 处理页号变更
   */
  handlePageChange (data: any) {
    this.queryApplicationData.page = data.currentPage
    this.queryApplicationData.pageSize = data.pageSize
    this.doPullDataList()
  }

  /// 拉取数据
  doPullDataList () {
    this.closeAllWebsocket()
    this.appList = []
    this.monitingPcs = {}
    this.queryApplicationData.pcServerId = this.pcServerId && this.pcServerId > 0 ? this.pcServerId : undefined
    ApplicationApi.query(this.queryApplicationData)
      .then((response) => {
        this.total = response.data?.total || 0
        this.pageSize = response.data?.pageSize || this.queryApplicationData.pageSize
        this.curPage = this.queryApplicationData.page
        this.appList = response.data!.items
      })
      .catch((err) => {
        this.$Notice.error({
          title: '查询应用数据失败',
          desc: err.message
        })
      })
  }

  /**
   * 删除实例
   */
  deleteInstance (appId: number | undefined, appInstanceId: number | undefined) {
    this.removeMonitingItem(appId as number, appInstanceId as number)
  }

  exportCommand () {
    showExportCommandsModal()
  }

  /**
   * 删除当前应用
   */
  deleteApplication () {
    this.doPullDataList()
  }

  /** 获取当前应用下实例的监控信息 */
  getMonitorInstances (id?: number) {
    const items = this.allMonitingAppIntance.filter((e) => e.appId === id)
    return items
  }

  /**
   * 显示新增应用的modal
   */
  showAddAppModal () {
    showAddApplicationModal().then((res) => {
      this.$router.push(`/maintain/application-page/${res}`)
      // this.doPullDataList()
    })
  }

  /**
   * 在监控集合中添加监控项
   * @param appId
   * @param instaceList
   */
  addMonitingItem (appId: number, instaceList: AppIntaceAtPc[]) {
    instaceList.forEach((item) => {
      let pcInstaceList = this.monitingPcs[item.pcId]
      if (!pcInstaceList) {
        pcInstaceList = []
        this.monitingPcs[item.pcId] = pcInstaceList
        this.openPcWss(item)
      }
      const monitorInstance = {
        appId: appId,
        appInstanceId: item.appInstanceId,
        appInstanceName: item.appInstanceName,
        status: appInstanceStatus.PendConntion
      }
      /// 将新的实例添加到列表中
      pcInstaceList.push(monitorInstance)
    })
    this.setAllMonitingAppIntance()
  }

  /**
   * 删除监控对象
   * @param appId
   * @param instanceId
   */
  removeMonitingItem (appId: number, instanceId: number) {
    for (const pcId in this.monitingPcs) {
      const pcInstaceList = this.monitingPcs[pcId]
      for (let i = pcInstaceList.length - 1; i >= 0; i--) {
        if (
          pcInstaceList[i].appId === appId &&
          pcInstaceList[i].appInstanceId === instanceId
        ) {
          pcInstaceList.splice(i, 1)
        }
      }
    }
    this.setAllMonitingAppIntance()
    this.clearEmptyMonitingPc()
  }

  /**
   * 删除整个应用所有实例的监控
   * @param appId
   */
  removeMonitingApp (appId: number) {
    for (const pcId in this.monitingPcs) {
      const pcInstaceList = this.monitingPcs[pcId]
      for (let i = pcInstaceList.length - 1; i >= 0; i--) {
        if (pcInstaceList[i].appId === appId) {
          pcInstaceList.splice(i, 1)
        }
      }
    }
    this.setAllMonitingAppIntance()
    this.clearEmptyMonitingPc()
  }

  /**
   * 更新应用实例的状态
   * @param pcId
   * @param instanceStatus
   */
  updatedPcInstance (pcId: number, instanceStatus: Record<string, boolean>, isError?: boolean) {
    const pcInstaceList = this.monitingPcs[pcId]
    if (!pcInstaceList) return

    pcInstaceList.forEach((item) => {
      if (isError) {
        item.status = appInstanceStatus.ConntionError
        return
      }
      let atPc = false
      for (const instanceName in instanceStatus) {
        if (item.appInstanceName === instanceName) {
          item.status = instanceStatus[instanceName]
            ? appInstanceStatus.Runing
            : appInstanceStatus.Stop
          atPc = true
        }
      }
      /// 没有在返加原结果列表的，则需要标识为没有部署
      if (!atPc) {
        item.status = appInstanceStatus.NodeDeploy
      }
    })
  }

  /**
   * 检测应用实例的状态是否更新，未更新则置为联结错误
   * @param pcId
   */
  checkPcInstance (pcId: number) {
    const pcInstaceList = this.monitingPcs[pcId]
    if (!pcInstaceList) return

    pcInstaceList.forEach((item) => {
      if (item.status === appInstanceStatus.PendConntion) {
        item.status = appInstanceStatus.ConntionError
      }
    })
  }

  /**
   * 清空已经没有需要监控的pc
   */
  clearEmptyMonitingPc () {
    for (const pcId in this.monitingPcs) {
      const pcInstaceList = this.monitingPcs[pcId]
      if (pcInstaceList.length === 0) {
        this.closePcWss(parseInt(pcId))
        delete this.monitingPcs[pcId]
      }
    }
  }

  /// 打开一个新的wss监控
  openPcWss (item: AppIntaceAtPc) {
    if (this.wssScoketList[item.pcId]) {
      return
    }
    if (!item.pcId) {
      return
    }
    const that = this
    appInstanceManger.getInstanceStatusMonitorWebSocket(item.pcId, item.pcPort, {
      fixed: 5000,
      callback: (data) => {
        that.$nextTick(() => {
          that.updatedPcInstance(item.pcId, data)
        })
      },
      onError (event) {
        that.$nextTick(() => {
          that.updatedPcInstance(item.pcId, {}, true)
        })
      }
    }).then((ws) => {
      /** 若10s后状态未变更则置为联结失败 */
      setTimeout(() => {
        this.checkPcInstance(item.pcId)
      }, 10000)
      this.wssScoketList[item.pcId] = ws
    }).catch((err) => {
      this.updatedPcInstance(item.pcId, {}, true)
      console.log(err)
    })
  }

  /// 关闭wss
  closePcWss (pcId: number) {
    this.wssScoketList[pcId]?.close()
    this.$delete(this.wssScoketList, pcId)
  }

  /**
   * 关闭所有ws链接
   */
  closeAllWebsocket () {
    Object.values(this.wssScoketList).forEach((ws) => {
      ws.close()
    })
    this.wssScoketList = {}
  }

  beforeDestroy () {
    this.closeAllWebsocket()
  }
}
