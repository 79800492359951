
import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'view-design'
import ProjectGroupSelectorInApp from '../selector/project-group-selector-in-app.vue'
import ProjectSelectorInApp from '../selector/project-selector-in-app.vue'
import PcServerSelectorInApp from '../selector/pc-server-selector-in-app.vue'
import ApplicationApi, { CreateNewImageAppRequest, CreateNewNoStandeAppRequest, CreateNewStandAppRequest } from '@/api/maintain/application'
import ImagesResourceApi, { ImagesResource } from '@/api/maintain/image-resource'
import GitBranchSelector from '@/components/selector/git-branch-selector.vue'
import GitTagSelector from '@/components/selector/git-tag-selector.vue'
import InstanceNginxSelector from '../selector/instance-nginx-selector.vue'
import AppInstanceNginxServerSelector from '../selector/app-instance-nginx-server-selector.vue'
import { AttachmentFileData } from '@/api/maintain/attachment-filedata'
import UploadButton from '@/components/upload-button.vue'
import showDownDockerfileModal from './show-down-dockerfile-modal'
import axios from 'axios'
import TeamResourceSelector from '@/components/common-select/team-resource-selector.vue'
import showAddBuildMountDirsModal from './show-add-build-mount-dirs-modal'

@Component({
  name: 'applicationAddModal',
  components: {
    ProjectGroupSelectorInApp,
    AppInstanceNginxServerSelector,
    InstanceNginxSelector,
    ProjectSelectorInApp,
    PcServerSelectorInApp,
    GitBranchSelector,
    GitTagSelector,
    UploadButton,
    TeamResourceSelector
  }
})
export default class ApplicationAddModal extends Vue {
  isShow = false
  // 判断应用类型, 1为标准应用, 2为非标应用, 3为镜像
  type = 1
  // 判断镜像资源为系统还是其他, 1为系统镜像资源, 2为其他资源
  imageType = 1
  // 标准应用下的应用类型名字
  appTypeName = ''
  // 标准应用表单
  addStandardFormData: CreateNewStandAppRequest = {}
  // 非标应用表单
  addNonStandardFormData: CreateNewNoStandeAppRequest = {
    wantAttNginx: false,
    buildDockfileUrl: '',
    buildMountDirs: ''
  }

  // 镜像应用表单
  addImageFormData: CreateNewImageAppRequest = {}
  // 镜像资源options
  imageList: ImagesResource[] = []
  // tag标签
  tags: string[] = []
  // 挂载目录
  dirs: string[] = []
  // tag内容
  tag = ''
  // 挂载目录模版
  dirTemplate = ''
  templateFlag = false
  // 挂载目录模版选项
  dirTemplateOptions = [
    {
      value: 'maven',
      label: 'maven'
    }
  ]

  /** 要显示的tag列表 */
  get showTags () {
    if (this.tags.length > 0) {
      return this.tags
    }
    return ['暂未设置标签']
  }

  StandardFormRules = {
    projectGroupId: [{ required: true, type: 'number', message: '请选择项目组', trigger: 'blur' }],
    projectId: [{ required: true, type: 'number', message: '请选择项目', trigger: 'blur' }],
    appName: [{ required: true, message: '请填写应用名称', trigger: 'blur' }, {
      pattern: /^[a-zA-Z][A-Za-z0-9_-]*$/,
      trigger: 'blur',
      message: '请输入英文字母与数字或下划线的组合，必须英文字母开头'
    }],
    gitBranch: [{ required: true, validator: this.validateStandardGit, trigger: 'blur' }],
    gitTag: [{ required: true, validator: this.validateStandardGit, trigger: 'blur' }],
    teamResourceId: [{ type: 'number', required: true, message: '请选择归属资源', trigger: 'change' }]
  }

  NonStandardFormRules = {
    appTitle: [{ required: true, message: '请填写应用标题', trigger: 'blur' }],
    appName: [
      { required: true, message: '请填写应用名称', trigger: 'blur' },
      {
        pattern: /^[a-zA-Z][A-Za-z0-9_-]*$/,
        trigger: 'blur',
        message: '请输入英文字母与数字或下划线的组合，必须英文字母开头'
      }
    ],
    gitUrl: [{ required: true, message: '请填写git地址', trigger: 'blur' }],
    gitUser: [{ required: true, message: '请填写git账号', trigger: 'blur' }],
    gitPwd: [{ required: true, message: '请填写git密码', trigger: 'blur' }],
    gitBranch: [{ required: true, validator: this.validateNonStandardGit, trigger: 'blur' }],
    gitTag: [{ required: true, validator: this.validateNonStandardGit, trigger: 'blur' }],
    teamResourceId: [{ type: 'number', required: true, message: '请选择归属资源', trigger: 'change' }]
  }

  ImageFormRules = {
    appName: [{ required: true, message: '请填写应用名称', trigger: 'blur' }, {
      pattern: /^[a-zA-Z][A-Za-z0-9_-]*$/,
      trigger: 'blur',
      message: '请输入英文字母与数字或下划线的组合，必须英文字母开头'
    }],
    imageId: [{ type: 'number', required: true, message: '请选择镜像资源', trigger: 'blur' }],
    imageName: [{ required: true, message: '请填写镜像名称', trigger: 'blur' }],
    appTitle: [{ required: true, message: '请填写镜像标题', trigger: 'blur' }],
    teamResourceId: [{ type: 'number', required: true, message: '请选择归属资源', trigger: 'change' }]
  }

  loading?: boolean = false;

  /** 镜像选择器加载状态 */
  selectLoading=false

  /** 上传等待事件 */
  onLoading (val: boolean) {
    this.loading = val
  }

  doCancel () {
    this.$emit('onCancel')
  }

  show () {
    this.isShow = true
  }

  close () {
    this.isShow = false
  }

  addTags () {
    if (this.tag) {
      this.tags.push(this.tag)
      this.tag = ''
    }
  }

  closeTag (index: number) {
    this.tags.splice(index, 1)
  }

  operationVersion: { label: string; value: string }[] = []

  created () {
    this.initOptions()
    axios.get('/runtime-container-list.json').then(res => {
      this.operationVersion = res.data.map((item: any) => {
        return {
          label: item.name,
          value: item.path
        }
      })
    })
  }

  showDownDockerfile () {
    showDownDockerfileModal()
  }

  /** 初始化镜像资源选项 */
  initOptions () {
    ImagesResourceApi.query({
      page: 1,
      pageSize: 10,
      sorts: []
    }).then((res) => {
      this.imageList = res.data?.items || []
    })
  }

  /** 加载镜像资源 */
  loadImageList (imageTitle: string) {
    ImagesResourceApi.queryByName(imageTitle)
      .then(response => {
        this.imageList = response.data || []
      })
  }

  /** 应用挂载目录模版 */
  useDirTemplate () {
    switch (this.dirTemplate) {
      case 'maven':
        this.dirs.push('/caches/.m2&/root/.m2')
        break
    }
  }

  /** 添加打包挂载目录 */
  addBuildMountDirs () {
    showAddBuildMountDirsModal().then(res => {
      this.dirs.push(`${res.key}&${res.value}`)
    })
  }

  /** 删除挂载目录 */
  closeDirs (index: number) {
    this.dirs.splice(index, 1)
  }

  /// git分支校验
  validateStandardGit (rule: any, value: string, callback: Function) {
    if (!this.addStandardFormData.gitBranch && !this.addStandardFormData.gitTag) {
      return callback(new Error('分支和标签不允许同时为空!'))
    }
    return callback()
  }

  /// git分支校验
  validateNonStandardGit (rule: any, value: string, callback: Function) {
    if (!this.addNonStandardFormData.gitBranch && !this.addNonStandardFormData.gitTag) {
      return callback(new Error('分支和标签不允许同时为空!'))
    }
    return callback()
  }

  /**
   * 标准应用-选择项目
   */
  selectProject (item: any) {
    this.$set(this.addStandardFormData, 'gitUrl', item.gitUrl)
    this.$set(this.addStandardFormData, 'gitName', item.gitName)
    this.$set(this.addStandardFormData, 'gitPwd', item.gitPwd)
    this.$set(this.addStandardFormData, 'appTitle', item.label)
    if (item.type === 1) {
      this.appTypeName = '标准应用-后端应用'
    } else if (item.type === 2) {
      this.appTypeName = '标准应用-前端应用'
    }
  }

  /**
   * 清除项目信息
   */
  clearProjectInfo () {
    this.$set(this.addStandardFormData, 'gitUrl', undefined)
    this.$set(this.addStandardFormData, 'gitName', undefined)
    this.$set(this.addStandardFormData, 'gitPwd', undefined)
    this.$set(this.addStandardFormData, 'appName', undefined)
    this.addStandardFormData.appTitle = undefined
    this.appTypeName = ''
  }

  /** 镜像类型变更 */
  imageTypeChange () {
    this.addImageFormData.imageId = undefined
    this.addImageFormData.imageName = undefined
  }

  /** 上传文件成功 */
  uploadOk (val: AttachmentFileData) {
    this.$set(this.addNonStandardFormData, 'buildDockfileUrl', `attr:${val.id}`)
  }

  /**
   * 保存提交应用
   */
  submit () {
    if (this.type === 1) {
      // 标准应用时
      const checkform = this.$refs.addStandardForm as Form
      checkform.validate((valid) => {
        if (valid) {
          this.addStandardFormData.appTags = this.tags.join(',')
          this.loading = true
          this.saveAppData()
        }
      })
    } else if (this.type === 2) {
      const checkform = this.$refs.addNonStandardForm as Form
      checkform.validate((valid) => {
        if (valid) {
          this.addNonStandardFormData.appTags = this.tags.join(',')
          this.addNonStandardFormData.buildMountDirs = this.dirs.join(',')
          if (!this.addNonStandardFormData.buildDockfileUrl) {
            this.$Message.warning('请上传dockerfile文件')
            return
          }
          this.loading = true
          this.saveAppData()
        }
      })
    } else {
      const checkform = this.$refs.addImageForm as Form
      checkform.validate((valid) => {
        if (valid) {
          this.addImageFormData.appTags = this.tags.join(',')
          this.loading = true
          this.saveAppData()
        }
      })
    }
  }

  /** 保存app信息 */
  saveAppData () {
    this.execAddApplication()
      .then((respone) => {
        this.$Notice.success({
          desc: '添加应用成功！'
        })
        this.$emit('onOk', respone.data?.id)
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '新增应用失败:' + err.message
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  /** 执行新增请求 */
  execAddApplication () {
    switch (this.type) {
      case 1:
        return ApplicationApi.addNewStandApp(this.addStandardFormData)
      case 2:
        return ApplicationApi.addNewNoStandeApp(this.addNonStandardFormData)
      case 3:
        return ApplicationApi.addNewImageApp(this.addImageFormData)
      default:
        return ApplicationApi.addNewStandApp(this.addStandardFormData)
    }
  }
}
