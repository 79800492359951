
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import showAppInstanceOptModal from './modal/app-instance-opt-modals'
import showNginxMountInstanceModal from './modal/show-nginx-mount-instance-modal'
import { appInstanceStatus } from '@/views/test/moniting-appintace-helper'
import { ApplicationType } from '@/api/maintain/application'

@Component({
  name: 'InstanceContainer'
})
export default class InstanceContainer extends Vue {
  /** 应用类型 */
  @Prop({
    type: Number,
    required: true
  })
  readonly applicationType!: number;

  /** 应用编码名 */
  @Prop({
    type: String,
    default: undefined
  })
  readonly applicationCodeName!: string;

  /** 实例信息 */
  @Prop({
    type: Object as PropType<AppInstance>,
    default: () => {
      return {}
    }
  })
  readonly instance!: AppInstance;

  /** 是否可编辑 */
  @Prop({
    type: Boolean,
    default: true
  })
  readonly canEdit!: boolean;

  /** 是否可编辑 */
  @Prop({
    type: Number,
    default: 0
  })
  readonly status!: number;

  /** 企业用户开通中 */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly opening!: boolean;

  /** 第一台服务器ip */
  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  readonly firstPcServerId!: number;

  appType = ApplicationType;

  /** 当前实例是否为webfront应用实例 */
  get isWebFrontInstance () {
    return [ApplicationType.WebFront, ApplicationType.NotStandardWebFront].includes(
      this.instance.applicationType
    )
  }

  /**
   * 是否可操作
   */
  get operaEffective () {
    if (this.isWebFrontInstance) {
      return true
    }
    return this.opening || (this.instance.pcServerId === this.firstPcServerId)
  }

  /** 编辑实例 */
  editInstance () {
    if (
      this.instance.applicationType === ApplicationType.WebFront ||
      this.instance.applicationType === ApplicationType.NotStandardWebFront
    ) {
      this.$router.push({
        name: 'webfront-page',
        params: {
          id: this.instance.id + '',
          tagName: this.instance.name
        }
      })
    } else {
      this.$router.push({
        name: 'instance-page',
        params: {
          id: this.instance.id + '',
          tagName: this.instance.name
        }
      })
    }
  }

  /** 调整nginx实例页面 */
  toNginxInstance () {
    if (this.instance.nginxInstanceId) {
      this.$router.push(`/maintain/instance-page/${this.instance.nginxInstanceId}`)
    }
  }

  /** 复制实例 */
  copyInstance () {
    if (
      this.instance.applicationType === ApplicationType.WebFront ||
      this.instance.applicationType === ApplicationType.NotStandardWebFront
    ) {
      this.$router.push({
        name: 'webfront-page',
        params: {
          id: this.instance.id + '',
          tagName: this.instance.name,
          copy: 'true'
        }
      })
    } else {
      this.$router.push({
        name: 'instance-page',
        params: {
          id: this.instance.id + '',
          tagName: this.instance.name,
          copy: 'true'
        }
      })
    }
  }

  /** 删除实例 */
  deleteInstance () {
    const that = this
    if (this.status === appInstanceStatus.ConntionError && !this.isWebFrontInstance) {
      that.$Modal.confirm({
        title: '确认',
        content: '<p>无法访问当前实例的服务器，是否确认删除当前实例？</p>',
        onOk: () => {
          // 删除实例
          AppInstanceApi.deleteInstance(this.instance.id as number)
            .then(() => {
              this.$emit('deleteInstance', this.instance.id)
            })
            .catch((err) => {
              this.$Notice.error({
                title: '错误',
                desc: '删除实例失败:' + err.message
              })
            })
        }
      })
    } else if (this.status !== appInstanceStatus.NodeDeploy && !this.isWebFrontInstance) {
      // 未删除容器
      this.$Message.error(
        '实例的容器还没有删除，请先进入实例【操作】界面，删除实例容器！'
      )
    } else {
      // 删除实例
      that.$Modal.confirm({
        title: '确认',
        content: '<p>是否确认删除实例？</p>',
        onOk: () => {
          // 删除实例
          AppInstanceApi.deleteInstance(this.instance.id as number)
            .then(() => {
              this.$emit('deleteInstance', this.instance.id)
            })
            .catch((err) => {
              this.$Notice.error({
                title: '错误',
                desc: '删除实例失败:' + err.message
              })
            })
        }
      })
    }
  }

  /** 实例部署页 */
  goInstanceDeployPage (id?: number) {
    this.$router.push(`/maintain/instance-deploy-page/${id}`)
  }

  /** 展示挂载的实例 */
  showNginxMountInstance (instanece: AppInstance) {
    showNginxMountInstanceModal(instanece)
  }

  @Watch('status')
  watchStatus (newValue: number) {
    this.$set(this.instance, 'status', newValue)
  }

  /** 显示实例配置 */
  showInstanceOptModal () {
    this.$set(this.instance, 'status', this.status)
    if (this.instance.id) {
      showAppInstanceOptModal(this.instance).then((res) => {
        this.$router.push(`/maintain/instance-deploy-page/${res}`)
      })
    }
  }

  /**
   * 获取实例背景
   */
  getInstanceBackground () {
    if (this.status === appInstanceStatus.Runing) {
      return 'app-server ins-running'
    }
    return 'app-server ins-no-deploy'
  }

  /**
   * 状态图标
   */
  getStatusIconType () {
    switch (this.status) {
      case appInstanceStatus.PendConntion:
        return 'ios-loading'
      case appInstanceStatus.Stop:
        return 'ios-pause'
      case appInstanceStatus.Runing:
        return 'ios-play'
      case appInstanceStatus.NodeDeploy:
        return 'ios-warning'
      case appInstanceStatus.ConntionError:
        return 'ios-bug'
      default:
        return 'ios-loading'
    }
  }

  /**
   * 状态图标样式
   * @param status
   */
  getStatusStyle () {
    switch (this.status) {
      case appInstanceStatus.PendConntion:
        return 'loading'
      case appInstanceStatus.Stop:
      case appInstanceStatus.NodeDeploy:
        return 'warning'
      case appInstanceStatus.Runing:
        return 'running'
      case appInstanceStatus.ConntionError:
        return 'error'
      default:
        return 'loading'
    }
  }

  /**
   * 实例状态文字转换
   */
  getInstanceStatusText () {
    switch (this.status) {
      case 0:
        return '待联结'
      case 2:
        return '停用'
      case 3:
        return '运行中'
      case 4:
        return '未部署'
      case 5:
        return '连接错误'
      default:
        return '未知'
    }
  }
}
