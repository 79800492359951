
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import ProjectGroupApi from '@/api/project/project-api'

// 团队选择组件
@Component({ name: 'ProjectSelector', components: {}, mixins: [] })
export default class ProjectSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number | string

  curValue = 0

  @Prop({
    type: String,
    default: '输入应用名称'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  @Prop({
    type: String,
    default: 'default'
  })
  size!: 'small' | 'default' | 'large'

  loading = false

  datas: Array<any> = []

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue)
    this.curValue = newValue.value
  }

  initData (userId: number) {
    this.$emit('input', undefined)
    this.curValue = -1
    this.userId = userId
    if (this.userId) {
      this.loadDataFromServer()
    }
  }

  mounted () {
    this.loadDataFromServer()
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    const queryData = {
      page: 1,
      pageSize: 100,
      sorts: []
    }
    ProjectGroupApi.allItemsByTeamId()
      .then((response) => {
        const data = response.data?.items
        this.datas = data!.map((e) => {
          return {
            value: e.id,
            label: e.title
          }
        })
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '加载应用下拉框失败:' + err.message
        })
      })
    this.loading = false
  }
}
