
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'

@Component({
  name: 'nginxMountInstanceModal',
  components: {
  }
})
export default class NginxMountInstanceModal extends Vue {
  /// 应用
  @Prop({
    required: true
  })
  nginxInstance!: AppInstance

  isShow = false;

  loading?: boolean = false

  mountInstanceList: Array<AppInstance> = []

  columns = [
    { title: '应用', key: 'applicationName' },
    { title: '应用类型', key: 'applicationTypeDest' },
    { title: '实例', key: 'name' },
    { title: '实例ip', key: 'pcServerIpAddress' }
  ]

  doCancel () {
    this.$emit('onCancel')
  }

  show () {
    this.isShow = true
  }

  close () {
    this.isShow = false
  }

  created () {
    // 获取跳转传递过来的实例id
    // this.loadDataFromServer()
  }

   @Watch('nginxInstance')
  onInstanceIdChange () {
    if (this.nginxInstance) {
      this.loadDataFromServer()
    }
  }

   /**
   * 加载数据
   */
   loadDataFromServer () {
     // 获取应用记录
     const nginxInstanceId = this.nginxInstance.id
     AppInstanceApi.listNginxMountedInstance(nginxInstanceId as number)
       .then((response) => {
         const data = response.data
         this.mountInstanceList = data || []
       })
       .catch((err) => {
         this.$Notice.error({
           title: '错误',
           desc: '获取nginx实例挂载实例失败:' + err.message
         })
       })
   }
}
