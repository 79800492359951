import { AppInstance } from './app-instance'

export interface AppConfigTypeEnumItem{
    /// 枚举项的值
    value: number;
    /// 枚举项名称
    name: string;
    /// 数据类型
    type?: string;
    /// 获取指令
    toCommand?: (instance: AppInstance, key: string, val: any) => string;
    /// 指令类型
    commandType?: 'OPTIONS'|'ARGS'|'COMMAND';
  }

/// 应用实例配置类型
const AppInstanceConfigType: Record<string, AppConfigTypeEnumItem> = {
  FROM: {
    value: 0,
    name: 'FROM',
    type: 'string'
  },
  PORT: {
    value: 1,
    name: 'PORT',
    type: 'integer',
    toCommand: (instance: AppInstance, key: string, val: any) => `-p ${key}:${val}`,
    commandType: 'OPTIONS'
  },
  VOLUME_DIR: {
    value: 2,
    name: 'VOLUME_DIR',
    type: 'string',
    toCommand: (instance: AppInstance, key: string, val: any) => `-v ${key}:${val}`,
    commandType: 'OPTIONS'
  },
  VOLUME_FILE: {
    value: 3,
    name: 'VOLUME_FILE',
    type: 'string'
  },
  ARG: {
    value: 4,
    name: 'ARG',
    type: 'string',
    toCommand: (instance: AppInstance, key: string, val: any) => `${key}=${val}`,
    commandType: 'ARGS'
  },
  ENV: {
    value: 5,
    name: 'ENV',
    type: 'string',
    toCommand: (instance: AppInstance, key: string, val: any) => `-e ${key}=${val}`,
    commandType: 'OPTIONS'
  },
  CONFIGFILE: {
    value: 6,
    name: 'CONFIGFILE',
    type: 'string'
  },
  CONFIGDIR: {
    value: 7,
    name: 'CONFIGDIR',
    type: 'string'
  },
  CMD: {
    value: 8,
    name: 'CMD',
    type: 'string'
  },
  RUN: {
    value: 9,
    name: 'RUN',
    type: 'string'
  },
  RESOURCE: {
    value: 10,
    name: 'RESOURCE',
    type: 'string'
  },
  ENTRYPOINT: {
    value: 11,
    name: 'ENTRYPOINT',
    type: 'string'
  },
  NET: {
    value: 12,
    name: 'NET',
    type: 'string'
  },
  NETWORKMODE: {
    value: 13,
    name: 'NETWORKMODE',
    type: 'string',
    toCommand: (instance: AppInstance, key: string, val: any) => {
      if (val === 'none') {
        return ''
      }
      return `-network=${val}`
    },
    commandType: 'OPTIONS'
  },
  WORKDIR: {
    value: 14,
    name: 'WORKDIR',
    type: 'string',
    toCommand: (instance: AppInstance, key: string, val: any) => {
      return `-w ${val}`
    },
    commandType: 'OPTIONS'
  },
  PRIVILEGED: {
    value: 15,
    name: 'PRIVILEGED',
    type: 'boolean',
    toCommand: (instance: AppInstance, key: string, val: any) => {
      if (val) {
        return '--privileged'
      }
      return ''
    },
    commandType: 'OPTIONS'
  },
  UNSETENV: {
    value: 16,
    name: 'UNSETENV',
    type: 'string'
  },
  ENV_DOCKERFILE: {
    value: 17,
    name: 'ENV_DOCKERFILE',
    type: 'string'
  },
  ADD: {
    value: 18,
    name: 'ADD',
    type: 'string'
  },
  COPY: {
    value: 19,
    name: 'COPY',
    type: 'string'
  },
  VOLUME_DOCKERFILE: {
    value: 20,
    name: 'VOLUME_DOCKERFILE',
    type: 'string'
  },
  RM_EXISTS: {
    value: 21,
    name: 'RM_EXISTS',
    type: 'boolean'
  },
  RESTART_POLICY: {
    value: 22,
    name: 'RESTART_POLICY',
    type: 'string',
    toCommand: (instance: AppInstance, key: string, val: any) => {
      return `-restart ${val}`
    },
    commandType: 'OPTIONS'
  },
  REMOVE: {
    value: 23,
    name: 'REMOVE',
    type: 'boolean',
    toCommand: (instance: AppInstance, key: string, val: any) => {
      if (val) {
        return '-rm'
      }
      return ''
    },
    commandType: 'OPTIONS'
  },
  CPUSET: {
    value: 24,
    name: 'CPUSET',
    type: 'integer',
    toCommand: (instance: AppInstance, key: string, val: any) => {
      return `--cpuset-cpus="${val}"`
    },
    commandType: 'OPTIONS'
  },
  MEMORY_LIMIT: {
    value: 25,
    name: 'MEMORY_LIMIT',
    type: 'integer',
    toCommand: (instance: AppInstance, key: string, val: any) => {
      return `-m ${val}M --memory-swap=${val}M`
    },
    commandType: 'OPTIONS'
  },
  TERMINAL: {
    value: 26,
    name: 'TERMINAL',
    type: 'boolean',
    toCommand: (instance: AppInstance, key: string, val: any) => {
      if (val) {
        return '-d'
      }
      return ''
    },
    commandType: 'OPTIONS'
  },
  LOG_FLOWER_SECOND: {
    value: 27,
    name: 'LOG_FLOWER_SECOND',
    type: 'integer'
  },
  BUILDARG: {
    value: 28,
    name: 'BUILDARG',
    type: 'string'
  },
  LOG_MAX_SIZE: {
    value: 29,
    name: 'LOG_MAX_SIZE',
    type: 'integer',
    toCommand: (instance: AppInstance, key: string, val: any) => `--log-driver journald --log-opt max-size=${val}M`,
    commandType: 'OPTIONS'
  }
}

export default AppInstanceConfigType
