import { showModal } from '@/libs/modal-helper'
import modal from './export-commands-modal.vue'

export default function showExportCommandsModal (): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {}, true, () => {
      resolve()
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
