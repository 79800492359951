
import { Prop, Vue, Component } from 'vue-property-decorator'
import ProjectGroupSelectorInApp from '../selector/project-group-selector-in-app.vue'
import ProjectSelectorInApp from '../selector/project-selector-in-app.vue'
import PcServerSelectorInApp from '../selector/pc-server-selector-in-app.vue'
import { AppInstance } from '@/api/maintain/app-instance'
import appInstanceManger from '@/api/maintain/app-instance-manger'
import { getterUserInfo } from '@/store'

@Component({
  name: 'AppInstanceOptModal',
  components: {
    ProjectGroupSelectorInApp,
    ProjectSelectorInApp,
    PcServerSelectorInApp
  }
})
export default class AppInstanceOptModal extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  instanceItem!: AppInstance

  isShow = false;
  gitUrl?: string = '';

  loading?: boolean = false;

  curFlux?: any = null

  get curInstance () {
    return this.instanceItem
  }

  /**
   * 实例状态文字转换
   */
  getInstanceStatusText () {
    switch (this.instanceItem.status) {
      case 0: return '待连接'
      case 2: return '已停止'
      case 3: return '运行中'
      case 4: return '未部署'
      case 5: return '连接错误'
      default: return '未知'
    }
  }

  /**
   * 当前编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateApplication
  }

  /**
   * 实例状态样式转换
   */
  getInstanceStatusStyle () {
    switch (this.instanceItem.status) {
      case 1: return 'color:#2add9c;font-weight:700;font-size:14px'
      case 2: return 'color:#ffcc00;font-weight:700;font-size:14px'
      case 3: return 'color:#000000;font-weight:700;font-size:14px'
      case 4: return 'color:#000000;font-weight:700;font-size:14px'
      default: return 'color:#000000;font-weight:700:font-size:14px'
    }
  }

  /// 跳到实例部署页面
  goInstanceDeployPage () {
    this.$emit('onOk', this.instanceItem.id)
  }

  /// 启动实例容器
  startContainer () {
    const that = this
    this.$Modal.confirm({
      title: '确认',
      content: '<p>是否确认启动实例？</p>',
      onOk: () => {
        appInstanceManger.startContainer(that.instanceItem.id!, {
          onOpen: () => {
            console.log('已连接')
          },
          onError: (err: Error) => {
            this.$Message.error('发生错误:' + err.message)
          },
          onMessage: () => {
            that.$Message.success('开启实例成功')
          }
        })
          .then((flux) => { this.curFlux = flux })
          .catch((err) => {
            this.$Message.error('开启容器异常:' + err.message)
          })
      }

    })
  }

  /// 停止实例容器
  stopContainer () {
    const that = this
    this.$Modal.confirm({
      title: '确认',
      content: '<p>是否确认停止实例？</p>',
      onOk: () => {
        appInstanceManger.stopContainer(that.instanceItem.id!, {
          onOpen: () => {
            console.log('已连接')
          },
          onError: (err: Error) => {
            this.$Message.error('发生错误:' + err.message)
          },
          onMessage: () => {
            that.$Message.success('停止实例成功')
          }
        })
          .then((flux) => { this.curFlux = flux })
          .catch((err) => {
            this.$Message.error('停止容器异常:' + err.message)
          })
      }
    })
  }

  /// 删除实例容器
  deleteContainer () {
    const that = this
    appInstanceManger.deleteContainer(this.instanceItem.id!, {
      onOpen: () => {
        console.log('已连接')
      },
      onError: (err: Error) => {
        this.$Message.error('发生错误:' + err.message)
      },
      onMessage: () => {
        that.$Message.success('删除实例成功')
      }
    }).then((flux) => { this.curFlux = flux })
      .catch((err) => {
        this.$Message.error('停止容器异常:' + err.message)
      })
  }

  doCancel () {
    this.$emit('onCancel')
  }

  show () {
    this.isShow = true
  }

  close () {
    this.isShow = false
  }

  /**
   * 释放资源
   */
  destroyed () {
    this.curFlux.close()
  }
}

