import { render, staticRenderFns } from "./project-selector-in-app.vue?vue&type=template&id=78c02a92&scoped=true&"
import script from "./project-selector-in-app.vue?vue&type=script&lang=ts&"
export * from "./project-selector-in-app.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c02a92",
  null
  
)

export default component.exports