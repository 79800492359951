
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import { BaseModal } from '@/libs/modal-helper'
import { Component, Vue } from 'vue-property-decorator'
import AppInstanceConfigApi from '@/api/maintain/app-instance-config'
import AppInstanceConfigType from '@/api/maintain/app-instance-config-type'
import JsPdf from 'jspdf'
import html2Canvas from 'html2canvas'

export interface CommandData {
  instanceName: string;
  command: string;
}

@Component({
  name: 'ExportCommandsModal'
})
export default class ExportCommandsModal extends Vue implements BaseModal {
  showModal = false;

  loading = true;

  commands = [] as CommandData[];

  columns = [
    {
      title: '实例名',
      key: 'instanceName',
      width: 160,
      align: 'center',
      className: 'instanceName'
    },
    {
      title: '运行指令',
      key: 'command',
      align: 'center',
      className: 'command'
    }
  ];

  created () {
    AppInstanceApi.getCurTeamAppInstanceListExceptWeb().then((res) => {
      res.data?.forEach((e) => this.getCommandByInstance(e))
      if (res.data) {
        Promise.all(res.data!.map((e) => this.getCommandByInstance(e))).then((res) => {
          this.commands = res
          this.loading = false
        })
      }
    })
  }

  getCommandByInstance (instance: AppInstance) {
    return new Promise<CommandData>((resolve) => {
      AppInstanceConfigApi.queryByInstanceId(instance.id!).then((res) => {
        const t = {
          OPTIONS: [],
          COMMAND: [],
          ARGS: []
        } as Record<string, string[]>
        res.data?.forEach((e) => {
          const item = AppInstanceConfigType[e.configTypeCode!]
          if (item.commandType && item.toCommand) {
            const val = item.toCommand(instance, e.configKey || '', e.configValue || '')
            if (t[item.commandType]) {
              t[item.commandType].push(val)
            } else {
              t[item.commandType] = [val]
            }
          }
        })
        const command = `docker run ${t.OPTIONS?.join(' ')} ${instance.containerName} ${
          (t.COMMAND?.length || 0) > 0 ? 'sh -c' : ''
        } ${t.COMMAND?.join('&&')} ${t.ARGS?.join(' ')}`
        resolve({
          instanceName: instance.name,
          command: command
        })
      })
    })
  }

  show (): void {
    this.showModal = true
  }

  close (): void {
    this.showModal = false
  }

  async doSave (): Promise<void> {
    const title = '实例运行指令'
    const shareContent = document.querySelector('.exportContent') as HTMLElement // 需要截图的包裹的（原生的）DOM 对象
    const width = shareContent.scrollWidth // 获取dom 宽度
    const height = shareContent.scrollHeight // 获取dom 高度

    // 页面重复次数
    let repeatTime = 0
    // 页面偏移
    let position = 0
    // 定义PDF
    const PDF = new JsPdf('p', 'pt', 'a4')
    while (height - repeatTime * 30000 >= 0) {
      // 定义canvas并且配置
      const canvas = document.createElement('canvas') // 创建一个canvas节点
      canvas.width = width // 定义canvas 宽度
      canvas.height =
        height - repeatTime * 30000 > 30000 ? 30000 : height - repeatTime * 30000 // 定义canvas高度
      canvas.style.width = width + 'px'
      canvas.style.height =
        (height - repeatTime * 30000 > 30000 ? 30000 : height - repeatTime * 30000) +
        'px'
      const opts = {
        imageTimeout: 15000,
        logging: false,
        canvas: canvas, // 自定义 canvas
        width: width, // dom 原始宽度
        height: height - repeatTime * 30000 > 30000 ? 30000 : height - repeatTime * 30000,
        windowWidth: width,
        windowHeight:
          height - repeatTime * 30000 > 30000 ? 30000 : height - repeatTime * 30000,
        scrollY: -repeatTime * 30000
      }

      shareContent.scrollTop = repeatTime * 30000

      await html2Canvas(shareContent, opts).then(() => {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = (contentWidth / 592.28) * 841.89
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 595.28
        const imgHeight = (592.28 / contentWidth) * contentHeight
        const pageData = canvas.toDataURL('image/jpeg', 1.0)

        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
      })
      position = 0
      repeatTime++
    }
    PDF.save(title + '.pdf'); // 这里是导出的文件名
    (this.$Spin as any).hide()
    this.$emit('onOk')
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
