import { render, staticRenderFns } from "./nginx-mount-instance-modal.vue?vue&type=template&id=0de50ef5&scoped=true&"
import script from "./nginx-mount-instance-modal.vue?vue&type=script&lang=ts&"
export * from "./nginx-mount-instance-modal.vue?vue&type=script&lang=ts&"
import style0 from "./nginx-mount-instance-modal.vue?vue&type=style&index=0&id=0de50ef5&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0de50ef5",
  null
  
)

export default component.exports