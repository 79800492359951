/**
 * 项目组模块
 */

import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
import { Project } from './project'

/**
 * 项目组服务请求api接口
 */
class ProjectGroupApi extends DefaultApiService<Project> {
  allItemsByProjectGroupId (projectGroupId: number): Promise<PageDataApiResult<Project>> {
    return this.request('allItemsByProjectGroupId/' + projectGroupId, 'POST') as any
  }

  /**
   * 添加一个webapi项目
   * @param orgName
   * @param name
   * @param title
   * @returns
   */
  addWebApiProject (orgName: string, name: string, title: string, projectGroupId: number, options?: string): Promise<DataApiResult<Project>> {
    return this.request('addWebApiProject', {
      projectName: name,
      orgName: orgName,
      title: title,
      projectGroupId: projectGroupId,
      options: options
    }, 'PUT')
  }

  /**
   * 添加一个webFront项目
   * @param name
   * @param title
   * @param projectGroupId
   * @returns
   */
  addWebFrontProject (name: string, title: string, projectGroupId: number): Promise<DataApiResult<Project>> {
    return this.request('addWebFrontProject', {
      orgName: 'com.xbg.test',
      projectName: name,
      title: title,
      projectGroupId: projectGroupId
    }, 'PUT')
  }

  allItemsByTeamId (): Promise<PageDataApiResult<Project>> {
    return this.request('allItemsByTeamId', 'POST') as any
  }
}

export default new ProjectGroupApi('/api/project')
