import { showModal } from '@/libs/modal-helper'
import modal from './application-add-modal.vue'

export default function showAddApplicationModal (): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    showModal<number>(modal, {}, true, (id: number) => {
      resolve(id)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
