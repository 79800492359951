
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import InstanceContainer from './instance-container.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { getShortUuid } from '@/libs/uuid'
import EmptyPage from '@/components/empty-page.vue'
import ApplicationApi, { Application, ApplicationType } from '@/api/maintain/application'
import { MonitingAppIntance } from '../moniting-appintace-helper'
import { PcServer } from '@/api/maintain/pc-server'
import { getterUserInfo } from '@/store'

@Component({
  name: 'ApplicationContainer',
  components: {
    InstanceContainer,
    swiper,
    swiperSlide,
    EmptyPage
  }
})
export default class ApplicationContainer extends Vue {
  /** 应用信息 */
  @Prop({
    type: Object as PropType<Application>,
    required: true
  })
  readonly application!: Application;

  /** 当前查询服务器ip */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  readonly curServerIpAddress!: string;

  /** 当前查询服务器ip */
  @Prop({
    type: Number,
    required: false,
    default: undefined
  })
  readonly curServerId!: number;

  /** 实例监控数据 */
  @Prop({
    type: Array as PropType<MonitingAppIntance[]>,
    default: []
  })
  readonly monitingAppIntances!: MonitingAppIntance[];

  /** 服务器列表 */
  @Prop({
    type: Array as PropType<PcServer[]>,
    default: []
  })
  readonly pcServers!: PcServer[];

  /** 企业用户开通中 */
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  readonly opening!: boolean;

  /** 第一台服务器ip */
  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  readonly firstPcServerId!: number;

  judgeTag = false; // 判断tag是否超出范围

  /** 应用实例列表 */
  instanceList: AppInstance[] = [];

  get canOperatePcServer () {
    const user = getterUserInfo()
    return user.canOperatePcServer
  }

  /** 轮播配置 */
  swiperOptions = {
    slidesPerView: 2,
    spaceBetween: 20,
    slidesPerGroup: 2,
    loop: false,
    loopFillGroupWithBlank: true,
    pagination: {
      el: '.swiper-pagination', // 与slot="pagination"处class一致
      clickable: true // 轮播按钮支持点击
    },
    autoplay: false, // 自动轮播
    speed: 1000 // 轮播速度
  };

  created () {
    this.loadInstanceData()
  }

  mounted () {
    const ele = this.$refs.tag as any
    this.judgeTag = ele && ele.scrollHeight > ele.clientHeight
  }

  @Watch('application.tags')
  watchTagsChange () {
    const ele = this.$refs.tag as any
    this.judgeTag = ele && ele.scrollHeight > ele.clientHeight
  }

  /** 根据实例id获取实例信息 */
  getAppInstanceById (id?: number) {
    return this.instanceList.find((e) => e.id === id)
  }

  /** 实例是否可编辑 */
  getCanEdit (id?: number) {
    const instance = this.instanceList.find((e) => e.id === id)
    if (!instance) {
      return false
    }
    const pcServer = this.pcServers.find((e) => e.id === instance.pcServerId)
    return this.canOperatePcServer || (pcServer && pcServer.canOperation)
  }

  /** 加载实例数据 */
  loadInstanceData () {
    AppInstanceApi.getAppInstanceByAppIdAndServerId(
      this.application.id!,
      this.curServerId > 0 ? this.curServerId : undefined
    )
      .then((res) => {
        this.instanceList = res.data!
        this.$emit(
          'instanceLoadFinish',
          this.application.id,
          this.instanceList.map((e) => {
            return {
              appInstanceId: e.id,
              appInstanceName: e.containerName,
              statusAtDB: e.status,
              pcId: e.pcServerId,
              pcIpAddress: e.pcServerIpAddress,
              pcPort: location.protocol === 'https:' ? e.httpsManagerPort : e.mangerPort
            }
          })
        )
      })
      .catch((err) => {
        this.$Notice.error({
          title: '查询实例数据失败',
          desc: err.message
        })
      })
  }

  /** 获取应用类型图标 */
  getTypeIcon () {
    switch (this.application.appType) {
      case ApplicationType.WebFront:
      case ApplicationType.NotStandardWebFront:
        return 'iconfont iconweb'
      case ApplicationType.ImageFromHub:
      case ApplicationType.ImageFromSystem:
        return 'iconfont icondocker'
      case ApplicationType.NotStandardApp:
        return 'iconfont iconapplication1'
      case ApplicationType.WebApi:
        return 'iconfont iconwebapi'
      default:
        return ''
    }
  }

  /** 删除实例 */
  deleteInstance (id?: number) {
    this.$emit('deleteInstance', this.application.id, id)
  }

  /** 删除应用 */
  deleteApplication () {
    if (this.monitingAppIntances.length) {
      this.$Message.error(
        '当前应用已经创建了实例，请先删除应用下的所有实例，再删除应用！'
      )
    } else {
      this.$Modal.confirm({
        title: '确认',
        content: '<p>确认要删除当前应用吗？</p>',
        onOk: () => {
          // 删除应用
          ApplicationApi.deleteApplication(this.application.id as number)
            .then(() => {
              this.$emit('deleteApplication')
            })
            .catch((err) => {
              this.$Notice.error({
                title: '错误',
                desc: '删除应用失败:' + err.message
              })
            })
        }
      })
    }
  }

  /** 应用详情 */
  goApplicationBaseInfo () {
    this.$router.push({
      name: 'application-page',
      params: {
        id: this.application.id + '',
        tagName: `修改${this.application.name}`
      }
    })
  }

  /** 开始部署 */
  goToApplicationDeploy () {
    this.$router.push({
      name: 'application-deploy',
      params: {
        id: this.application.id + '',
        tagName: `应用${this.application.name}部署`
      }
    })
  }

  /** 新增实例 */
  addInstance () {
    let path = '/maintain/instance-page/new'
    if (this.application.appType === 2 || this.application.appType === 6) {
      path = '/maintain/webfront-page/new'
    }
    this.$router.push({
      path: path,
      query: {
        appId: this.application.id?.toString(),
        appName: this.application.name?.toString(),
        appType: this.application.appType?.toString(),
        appTypeName: this.application.appTypeName,
        defaultConfigAlerts: this.application.defaultConfigAlerts,
        defaultConfigFile: this.application.defaultConfigFile,
        imageType: this.application.imageType?.toString(),
        key: getShortUuid()
      }
    })
  }

  /** 分割应用标签字符串获取标签列表 */
  applicationTag (tags: string | undefined) {
    if (tags) {
      return tags.split(',')
    }
    return []
  }
}
