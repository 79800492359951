
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import ProjectGroupApi from '@/api/project/project-api'

// 团队选择组件
@Component({ name: 'projectSelectorInApp', components: {}, mixins: [] })
export default class ProjectSelectorInApp extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number | string

  curValue = 0

  @Prop({
    type: String,
    default: '输入应用名称'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  @Prop({
    type: String,
    default: 'default'
  })
  size!: 'small' | 'default' | 'large'

  loading = false

  datas: Array<any> = []

  @Prop({
    type: Number
  })
  projectGroupId!: number

  @Watch('projectGroupId')
  onProjectGroupIdChange () {
    if (this.projectGroupId && this.projectGroupId > 0) {
      this.loadDataFromServer()
    }
    this.$emit('input', undefined)
  }

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
    for (let i = 0; i < this.datas.length; i++) {
      if (this.datas[i].value === newValue.value) {
        this.$emit('on-select-item', this.datas[i])
        return
      }
    }
  }

  initData (userId: number) {
    this.$emit('input', undefined)
    this.curValue = -1
    this.userId = userId
    if (this.userId) {
      this.loadDataFromServer()
    }
  }

  mounted () {
    // this.loadDataFromServer()
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    const queryData = {
      page: 1,
      pageSize: 100,
      sorts: []
    }
    ProjectGroupApi.allItemsByProjectGroupId(this.projectGroupId)
      .then((response) => {
        const data = response.data?.items
        this.datas = data!.map((e) => {
          return {
            value: e.id,
            label: e.title,
            gitUrl: e.gitUrl,
            gitName: e.gitUser,
            gitPwd: e.gitPwd,
            type: e.type,
            name: e.name,
            title: e.title,
            projectGroupId: e.projectGroupId
          }
        })
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '加载应用下拉框失败:' + err.message
        })
      })
    this.loading = false
  }
}
