import { showModal } from '@/libs/modal-helper'
import modal from './down-dockerfile-modal.vue'

export default function showDownDockerfileModal (): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {
    }, true, () => {
      return new Promise<void>((resolve, reject) => {
        resolve()
      })
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
