
import { Prop, Vue, Component, Model } from 'vue-property-decorator'

// 团队选择组件
@Component({ name: 'ApplicationTypeSelector', components: {}, mixins: [] })
export default class ApplicationTypeSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  curValue = 0

  @Prop({
    type: String,
    default: '输入系统名称或者版本'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number

  @Prop({
    type: String,
    default: undefined
  })
  size?: string

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData (userId: number) {
    this.$emit('input', undefined)
    this.curValue = -1
    this.userId = userId
    if (this.userId) {
      this.loadDataFromServer()
    }
  }

  mounted () {
    this.loadDataFromServer()
  }

dataList = [
  {
    value: 1,
    name: 'webapi',
    label: '标椎应用-后台应用'
  },
  {
    value: 2,
    name: 'webfront',
    label: '标椎应用-前端应用'
  },
  {
    value: 3,
    name: 'notStandardWebapi',
    label: '非标准后端项目应用'
  },
  {
    value: 4,
    name: 'imageFromSystem',
    label: '镜像应用-系统镜像资源'
  },
  {
    value: 5,
    name: 'imageFromHub',
    label: '镜像应用-从docker仓库拉取'
  },
  {
    value: 6,
    name: 'notStandardWebfront',
    label: '非标准前端项目应用'
  }
]

// 加载下拉框选项
loadDataFromServer () {
  this.loading = true
  this.datas = this.dataList.filter((e) => {
    return {
      value: e.value,
      label: e.label
    }
  })
  this.loading = false
}
}
