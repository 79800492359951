
import { Vue, Component } from 'vue-property-decorator'
import FileSaver from 'file-saver'
import axios from 'axios'

@Component({
  name: 'nginxMountInstanceModal',
  components: {
  }
})
export default class DownDockerfileModal extends Vue {
  isShow = false
  curDockerfileTemplate = null

  doCancel () {
    this.$emit('onCancel')
  }

  dockerfileTemplate = [
    {
      value: 'maven',
      label: 'maven模版'
    },
    {
      value: 'gradle',
      label: 'gradle模版'
    }
  ]

  show () {
    this.isShow = true
  }

  close () {
    this.isShow = false
  }

  download () {
    if (this.curDockerfileTemplate) {
      axios.get(`/deploy/appdockerfile-template/${this.curDockerfileTemplate}.build.dockerfile`)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'text/plain;charset=utf-8' })
          FileSaver.saveAs(blob, `${this.curDockerfileTemplate}.build.dockerfile`)
          this.isShow = false
        })
        .catch((err) => {
          this.$Message.warning({
            content: '下载失败: ' + err,
            duration: 5,
            closable: true
          })
        })
    }
  }
}
